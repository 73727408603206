import React from "react";
import { Route, Switch, BrowserRouter, Router } from "react-router-dom";
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent";
import NavComponent from "../components/NavComponent";
import "../styles/Panel.scss";
import DesktopPage from "./DesktopPage";
import OrdersPage from "./OrdersPage";
import OrderPage from "./OrderPage";
import OrderShippingGLSPage from "./OrderShippingGLSPage";
import OrderShippingDPDPage from "./OrderShippingDPDPage";
import OrderShippingPaczkomatInPost from "./OrderShippingPaczkomatInPost";
import OrderShippingCourierInPostPage from "./OrderShippingCourierInPostPage";
import OrderShippingGLSLabelDownloadPage from "./OrderShippingGLSLabelDownloadPage";
import * as Icon from "react-feather";
import OrderShippingManualPage from "./OrderShippingManualPage";
import ComponentNum from "../components/override/ComponentNum";
import AfterLoginPage from "./AfterLoginPage";
import OrderShippingDPDLabelDownloadPage from "./OrderShippingDPDLabelDownloadPage";
import StatsShopsPage from "./StatsShopsPage";
import StatsAllegroPage from "./StatsAllegroPage";
import OrderShippingPaczkomatInPostLabelDownloadPage from "./OrderShippingPaczkomatInPostLabelDownloadPage";
import OrderShippingCourierInPostLabelDownloadPage from "./OrderShippingCourierInPostLabelDownloadPage";

class PanelPage extends ComponentNum {
  constructor(props) {
    super(props);
  }

  state = {};

  componentDidMount() {
    axios
      .get("/")
      .then((res) => {
        console.log("res");
        console.log(res);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  render() {
    return (
      <div id="panel">
        <HeaderComponent />
        <div className="container-fluid">
          <div className="row">
            <NavComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <Switch>
                <Route exact path="/" component={DesktopPage} />
                <Route exact path="/after-login-page" component={AfterLoginPage} />
                <Route exact key="orders-all" path="/orders" component={OrdersPage} />
                <Route exact key="orders-shop" path="/orders/shop/:shop" component={OrdersPage} />
                <Route exact key="orders-search" path="/orders/search/:search" component={OrdersPage} />
                {/* <Route key="orders-shop-uk" path="/orders/shop/:shop" component={OrdersPage} /> */}
                <Route exact path="/orders/order/:reference" component={OrderPage} />
                <Route exact path="/orders/order/shipping/gls/:reference" component={OrderShippingGLSPage} />
                <Route exact path="/orders/order/shipping/dpd/:reference" component={OrderShippingDPDPage} />
                <Route exact path="/orders/order/shipping/paczkomatinpost/:reference" component={OrderShippingPaczkomatInPost} />
                <Route exact path="/orders/order/shipping/courierinpost/:reference" component={OrderShippingCourierInPostPage} />
                <Route exact path="/orders/order/shipping/manual/:reference" component={OrderShippingManualPage} />
                <Route exact path="/orders/order/shipping/label/gls/:reference/:packageId" component={OrderShippingGLSLabelDownloadPage} />
                <Route exact path="/orders/order/shipping/label/dpd/:reference/:packageId" component={OrderShippingDPDLabelDownloadPage} />
                <Route exact path="/orders/order/shipping/label/paczkomatinpost/:reference/:packageId" component={OrderShippingPaczkomatInPostLabelDownloadPage} />
                <Route exact path="/orders/order/shipping/label/courier-inpost/:reference/:packageId" component={OrderShippingCourierInPostLabelDownloadPage} />

                <Route exact key="stats-shops" path="/stats/shops" component={StatsShopsPage} />
                <Route exact key="stats-allegro" path="/stats/allegro" component={StatsAllegroPage} />

                {/* <Route exact path="/panel" component={PanelPage} /> */}
              </Switch>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default PanelPage;
