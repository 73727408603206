import React from "react";
import { NavLink } from "react-router-dom";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import axios from "axios";
import utils from "../utils/utils.js";
import * as Icon from "react-feather";
import ComponentNum from "../components/override/ComponentNum";

class OrderShippingDPDLabelDownloadPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders/order/shipping/label/gls/");

    this.URL = "/shipment/get-package-label/dpd/" + props.match.params.packageId;
    this.packageId = props.match.params.packageId;
    this.reference = props.match.params.reference;
    this.state = {
      loading: true,
      label: null,
    };
  }

  componentDidMount() {
    utils.checkPermissions();

    axios
      .get(this.URL, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.packageId + ".pdf");
        document.body.appendChild(link);
        link.click();

        this.setState({
          loading: false,
        });
        window.location = "/orders/order/" + this.reference;
      })
      .catch((err) => {});
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienie numer: ..." />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    const breadcrumbItems = [
      { name: "Panel", path: "/" },
      { name: "Lista zamówień", path: "/orders" },
      { name: "Zamówienie numer: " + this.reference, path: "/orders/order/" + this.reference },
      { name: "Etykieta (DPD)", path: "" },
    ];

    return (
      <>
        <PageHeaderComponent title={"Zamówienie numer: " + this.reference} orderRe />
        <PageBodyComponent>
          <BreadcrumbComponent items={breadcrumbItems}></BreadcrumbComponent>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrderShippingDPDLabelDownloadPage;
