import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// require("bootstrap");
import "@popperjs/core/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import LoginPage from "./pages/LoginPage";
import PanelPage from "./pages/PanelPage";
import axios from "axios";

// axios.defaults.baseURL = "https://127.0.0.1:8000/";
axios.defaults.baseURL = "https://api.markasystem.com/";

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "https://api.markasystem.com/";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "http://localhost:3000";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// axios.defaults.headers.common["Accept"] = "*/*";
// axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["Origin"] = "*";

axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
axios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "X-Custom-Auth, Accept,Authorization,Cache-Control,Content-Type,DNT,If-Modified-Since,Keep-Alive,Origin,User-Agent,X-Requested-With";

// axios.defaults.withCredentials = true;

if (localStorage.getItem("jwt-token") !== null) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt-token");
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: true,
    };

    if (localStorage.getItem("jwt-token") !== null) {
      this.state = {
        loggedIn: true,
      };
    } else {
      this.state = {
        loggedIn: false,
      };
    }
  }

  componentDidMount() {}

  render() {
    return (
      // <BrowserRouter>
      //   <Switch>
      //     <Route exact path="/" component={LoginPage} />
      //     <Route exact path="/login" component={LoginPage} />
      //     <Route exact path="/panel" component={PanelPage} />
      //   </Switch>
      // </BrowserRouter>

      <BrowserRouter>
        <Route render={() => (this.state.loggedIn ? <PanelPage /> : <LoginPage />)} />
      </BrowserRouter>
    );
  }
}

export default App;
