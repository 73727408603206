import React from "react";
import * as Icon from "react-feather";
import utils from "../utils/utils";

function HeaderComponent() {
  function handleClickLogOut() {
    utils.logout();
  }

  return (
    <>
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
          "MARKA" System
        </a>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-nav">
          <div className="nav-item text-nowrap">
            <span className="nav-link px-3 logout-btn" onClick={handleClickLogOut}>
              <Icon.Power className="feather" /> Wyloguj się
            </span>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderComponent;
