import React from "react";

function CountriesFlagsComponent(params) {
  const importAll = (require) =>
    require.keys().reduce((acc, next) => {
      acc[next.replace("./", "")] = require(next);
      return acc;
    }, {});

  const images = importAll(require.context("../images/flags/", false, /\.(svg)$/));

  return (
    <>
      <img style={params.style} alt={params.alt} src={images[params.countryCode + ".svg"].default} />
    </>
  );
}

export default CountriesFlagsComponent;
