import React from "react";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import utils from "../utils/utils.js";
import axios from "axios";
import { fireEvent } from "@testing-library/dom";
import ComponentNum from "../components/override/ComponentNum";
import carrierLogo from "../images/carriers/gls_logo.png";

class OrderShippingPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders/order/shipping/gls/");

    this.URL = "/orders/get/" + props.match.params.reference;
    this.state = {
      loading: true,
      order: null,
      shippmentAddress: null,
    };
  }

  componentDidMount() {
    utils.checkPermissions();

    axios
      .get(this.URL)
      .then((res) => {
        let tempShippmentAddress = JSON.parse(res.data.order).additionalDataNOdb.addressDelivery;
        tempShippmentAddress["reference"] = res.data.order.reference;

        this.setState({
          loading: false,
          order: JSON.parse(res.data.order),
          shippmentAddress: tempShippmentAddress,
        });

        fireEvent.change(document.getElementById("ship-country"), { target: { value: this.state.shippmentAddress.alpha2code } });

        //this.setPatternToZipField();
      })
      .catch((err) => {});
  }

  handleChange = (event) => {
    let tempShippmentAddress = this.state.shippmentAddress;
    tempShippmentAddress[event.target.name] = event.target.value;
    this.setState({
      shippmentAddress: tempShippmentAddress,
    });
  };

  handleChangeCountry = (event) => {
    this.handleChange(event);
    this.setPatternToZipField();
  };

  setPatternToZipField = () => {
    let countrySelect = document.getElementById("ship-country");
    let selectedCountry = countrySelect.options[countrySelect.selectedIndex];
    selectedCountry.click();
    let zipField = document.getElementById("ship-zip");
    zipField.setAttribute("pattern", selectedCountry.dataset.pattern);
    document.getElementById("zip-min").innerText = selectedCountry.dataset.min;
    document.getElementById("zip-max").innerText = selectedCountry.dataset.max;
    document.getElementById("zip-pattern").innerText = selectedCountry.dataset.humanpattern;
    // [0-9]{2}-[0-9]{3}
  };

  handleFilterCountries = (event) => {
    let keyword = event.target.value;
    let countrySelect = document.getElementById("ship-country");

    for (var i = 0; i < countrySelect.length; i++) {
      var txt = countrySelect.options[i].text;
      if (txt.substring(0, keyword.length).toLowerCase() !== keyword.toLowerCase() && keyword.trim() !== "") {
        countrySelect.options[i].style.display = "none";
      } else {
        countrySelect.options[i].style.display = "block";
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post("/shipment/send-package/gls", JSON.stringify(this.state.shippmentAddress), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res");
        console.log(res);

        if (res.data.status === "OK" && res.data.message === "Package added") {
          //window.location = "/orders/order/" + this.state.order.reference;
          window.location = "/orders/order/shipping/label/gls/" + this.state.order.reference + "/" + res.data.packageId;
        } else {
          utils.showAlert("error", res.data.message);
        }

        this.setState({
          loading: false,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienie numer: ..." />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    const breadcrumbItems = [
      { name: "Panel", path: "/" },
      { name: "Lista zamówień", path: "/orders" },
      { name: "Zamówienie numer: " + this.state.order.reference, path: "/orders/order/" + this.state.order.reference },
      { name: "Wysyłka (GLS)", path: "" },
    ];

    const fullAddress =
      (this.state.shippmentAddress.address1 ? this.state.shippmentAddress.address1 : "") + (this.state.shippmentAddress.address2 ? " " + this.state.shippmentAddress.address2 : "");

    return (
      <>
        <PageHeaderComponent title={"Zamówienie numer: " + this.state.order.reference} />
        <PageBodyComponent>
          <BreadcrumbComponent items={breadcrumbItems}></BreadcrumbComponent>
          <div className="row border-bottom mb-4 pb-4 pt-2">
            <div className="col-md-2 col-sm-12">
              <img style={{ height: "auto", width: "auto", maxWidth: "100%" }} src={carrierLogo} alt="logo" />
            </div>
            <div className="col-md-10 col-sm-12">
              <h3>Formularz tylko dla wysyłek GLS</h3>
              <p>Sprwadź poprawność danych i kraj.</p>
            </div>
          </div>

          <form className="" onSubmit={this.handleSubmit}>
            <div className="row mb-3">
              <label htmlFor="ship-firstname" className="col-sm-12 col-md-2 col-form-label">
                <strong>Imię (pierwsze) *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-firstname"
                  name="firstname"
                  defaultValue={this.state.shippmentAddress.firstname.substring(0, 40)}
                  required
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-middlename" className="col-sm-12 col-md-2 col-form-label">
                Imię (drugie)
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-middlename"
                  name="middlename"
                  defaultValue={this.state.shippmentAddress.middlename ? this.state.shippmentAddress.middlename.substring(0, 40) : ""}
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-lastname" className="col-sm-12 col-md-2 col-form-label">
                <strong>Nazwisko *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-lastname"
                  name="lastname"
                  defaultValue={this.state.shippmentAddress.lastname.substring(0, 40)}
                  required
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-4">
              <label htmlFor="ship-country" className="col-sm-12 col-md-2 col-form-label">
                <strong>Kraj *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input type="text" placeholder="Szukaj kraju" autoComplete="off-auto" className="form-control" onChange={this.handleFilterCountries} id="search-country" />
                <select
                  size="10"
                  className="form-select"
                  id="ship-country"
                  name="country"
                  required
                  onChange={this.handleChangeCountry}
                  //   defaultValue={this.state.shippmentAddress.alpha2code}
                >
                  <option value="AL" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Albania (AL)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="DZ" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Algieria (DZ)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="AS" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Samoa Amerykanskie (AS)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="AD" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Andora (AD)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="AO" data-humanpattern="!!!!!!" data-pattern="[A-Za-z0-9]{6}" data-min="6" data-max="6">
                    Angola (AO)&nbsp;&nbsp;&nbsp;!!!!!!
                  </option>
                  <option value="AI" data-humanpattern="$####$$$" data-pattern="[A-Za-z]{1}[A-Za-z0-9]{4}[A-Za-z]{3}" data-min="8" data-max="8">
                    Anguilla (AI)&nbsp;&nbsp;&nbsp;$$-####
                  </option>
                  <option value="AR" data-humanpattern="$####$$$" data-pattern="[A-Za-z]{1}[A-Za-z0-9]{4}[A-Za-z]{3}" data-min="8" data-max="8">
                    Argentyna (AR)&nbsp;&nbsp;&nbsp;$####$$$
                  </option>
                  <option value="AM" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Armenia (AM)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="AU" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Australia (AU)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="AT" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Austria (AT)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="AZ" data-humanpattern="$$ ####" data-pattern="[A-Za-z]{2} [A-Za-z0-9]{4}" data-min="7" data-max="7">
                    Azerbejdzan (AZ)&nbsp;&nbsp;&nbsp;$$ ####
                  </option>
                  <option value="BS" data-humanpattern="$-#####" data-pattern="[A-Za-z]{1}-[A-Za-z0-9]{5}" data-min="7" data-max="7">
                    Bahamy (BS)&nbsp;&nbsp;&nbsp;$-#####
                  </option>
                  <option value="BH" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Bahrajn (BH)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="BD" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Bangladesz (BD)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="BB" data-humanpattern="$$#####" data-pattern="[A-Za-z]{2}[A-Za-z0-9]{5}" data-min="7" data-max="7">
                    Barbados (BB)&nbsp;&nbsp;&nbsp;$$#####
                  </option>
                  <option value="BY" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Bialorus (BY)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="BE" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Belgia (BE)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="BM" data-humanpattern="$$ !!" data-pattern="[A-Za-z]{2} [A-Za-z0-9]{2}" data-min="5" data-max="5">
                    Bermudy (BM)&nbsp;&nbsp;&nbsp;$$ !!
                  </option>
                  <option value="BT" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Bhutan (BT)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="BO" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Boliwia (BO)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="BA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Bosnia Hercegowina (BA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="BR" data-humanpattern="#####-###" data-pattern="[0-9]{5}-[0-9]{3}" data-min="9" data-max="9">
                    Brazylia (BR)&nbsp;&nbsp;&nbsp;#####-###
                  </option>
                  <option value="BN" data-humanpattern="$$####" data-pattern="[a-zA-Z]{2}[0-9]{4}" data-min="6" data-max="6">
                    Brunei (BN)&nbsp;&nbsp;&nbsp;$$####
                  </option>
                  <option value="BG" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Bulgaria (BG)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="KH" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kambodza (KH)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CA" data-humanpattern="!!! !!!" data-pattern="[A-Za-z0-9]{3}-[A-Za-z0-9]{3}" data-min="7" data-max="7">
                    Kanada (CA)&nbsp;&nbsp;&nbsp;!!! !!!
                  </option>
                  <option value="CV" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Republika Zielonego Przyladka (CV)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="KY" data-humanpattern="$$#-####" data-pattern="[A-Za-z]{2}[0-9]{1}-[0-9]{4}" data-min="8" data-max="8">
                    Kajmany (KY)&nbsp;&nbsp;&nbsp;$$#-####
                  </option>
                  <option value="CL" data-humanpattern="#######" data-pattern="[0-9]{7}" data-min="7" data-max="7">
                    Chile (CL)&nbsp;&nbsp;&nbsp;#######
                  </option>
                  <option value="CN" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Chiny (CN)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="CO" data-humanpattern="!!!!!!!" data-pattern="[A-Za-z0-9]{7}" data-min="7" data-max="7">
                    Kolumbia (CO)&nbsp;&nbsp;&nbsp;!!!!!!!
                  </option>
                  <option value="CG" data-humanpattern="!!!!!" data-pattern="[A-Za-z0-9]{5}" data-min="5" data-max="5">
                    Kongo (CG)&nbsp;&nbsp;&nbsp;!!!!!
                  </option>
                  <option value="CK" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Wyspy Cooka (CK)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="CR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kostaryka (CR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="HR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Chorwacja (HR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CU" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kuba (CU)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CY" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Cypr (CY)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="ME" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Montenegro (ME)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CZ" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Czechy (CZ)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="DK" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Dania (DK)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="DO" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Dominikana (DO)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="EC" data-humanpattern="$!###!##" data-pattern="[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{3}[a-zA-Z0-9]{1}[0-9]{3}" data-min="8" data-max="8">
                    Ekwador (EC)&nbsp;&nbsp;&nbsp;$!###!##
                  </option>
                  <option value="EG" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Egipt (EG)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SV" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Salwador (SV)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="EE" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Estonia (EE)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="ET" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Etiopia (ET)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="FO" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Wyspy Owcze (FO)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="FI" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Finlandia (FI)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GF" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Gujana Franuska (GF)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="PF" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Polinezja Francuska (PF)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="FR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Francja (FR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GE" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Gruzja (GE)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="DE" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Niemcy (DE)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GI" data-humanpattern="$! !!!;$!! !!!;$!!! !!!" data-pattern="[A-Za-z]{1}[A-Za-z0-9]{1,3} [A-Za-z0-9]{3}" data-min="6" data-max="8">
                    Gibraltar (GI)&nbsp;&nbsp;&nbsp;$! !!!;$!! !!!;$!!! !!!
                  </option>
                  <option value="GR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Grecja (GR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GL" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Grenlandia (GL)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="GP" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Gwadelupa (GP)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GU" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Guam (GU)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="GT" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Gwatemala (GT)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="GW" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Gwinea Bissau (GW)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="GN" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Gwinea (GN)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="HT" data-humanpattern="$$####" data-pattern="[a-zA-Z]{2}[0-9]{4}" data-min="6" data-max="6">
                    Haiti (HT)&nbsp;&nbsp;&nbsp;$$####
                  </option>
                  <option value="HN" data-humanpattern="$$####" data-pattern="[a-zA-Z]{2}[0-9]{4}" data-min="6" data-max="6">
                    Honduras (HN)&nbsp;&nbsp;&nbsp;$$####
                  </option>
                  <option value="HK" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Hong Kong (HK)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="HU" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Wegry (HU)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="IS" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Islandia (IS)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="IN" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Indie (IN)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="ID" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Indonezja (ID)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="IR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Iran (IR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="IQ" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Irak (IQ)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="IE" data-humanpattern="$#! !!!!;!!!!!!!!!" data-pattern="[a-zA-Z0-9]{8,9}" data-min="8" data-max="9">
                    Irlandia (IE)&nbsp;&nbsp;&nbsp;$#! !!!!;!!!!!!!!!
                  </option>
                  <option value="IL" data-humanpattern="#######" data-pattern="[0-9]{7}" data-min="7" data-max="7">
                    Izrael (IL)&nbsp;&nbsp;&nbsp;#######
                  </option>
                  <option value="IT" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Wlochy (IT)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CI" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Wybrzeze Kosci Sloniowej (CI)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="JP" data-humanpattern="###-####" data-pattern="[0-9]{3}-[0-9]{4}" data-min="8" data-max="8">
                    Japonia (JP)&nbsp;&nbsp;&nbsp;###-####
                  </option>
                  <option value="JO" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Jordania (JO)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="KZ" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Kazachstan (KZ)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="KE" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kenia (KE)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="XK" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kosowo (XK)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="KW" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Kuwejt (KW)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="KG" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Kirgistan (KG)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="LA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Laos (LA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="LV" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Lotwa (LV)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="LB" data-humanpattern="####-####" data-pattern="[0-9]{4} [0-9]{4}" data-min="9" data-max="9">
                    Liban (LB)&nbsp;&nbsp;&nbsp;#### ####
                  </option>
                  <option value="LS" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Lesotho (LS)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="LR" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Liberia (LR)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="LI" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Lichtenstein (LI)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="LT" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Litwa (LT)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="LU" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Luksemburg (LU)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="MK" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Macedonia (MK)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="MG" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Madagaskar (MG)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="MY" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Malezja (MY)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="MV" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Malediwy (MV)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="MT" data-humanpattern="$$ ####" data-pattern="[A-Za-z]{2} [A-Za-z0-9]{4}" data-min="7" data-max="7">
                    Malta (MT)&nbsp;&nbsp;&nbsp;$$$ ####
                  </option>
                  <option value="MA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Maroko (MA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="MH" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Wyspy Marshalla (MH)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="MQ" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Martynika (MQ)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="MU" data-humanpattern="###$$###" data-pattern="[0-9]{3}[a-zA-Z]{2}[0-9]{3}" data-min="8" data-max="8">
                    Mauritius (MU)&nbsp;&nbsp;&nbsp;###$$###
                  </option>
                  <option value="MX" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Meksyk (MX)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="FM" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Mikronezja (FM)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="MD" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Moldawia (MD)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="MN" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Mongolia (MN)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="MZ" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Mozambik (MZ)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="MM" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Myanmar (Birma) (MM)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="NP" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Nepal (NP)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="NL" data-humanpattern="#### $$" data-pattern="[0-9]{4} [A-Za-z]{2}" data-min="7" data-max="7">
                    Holandia (NL)&nbsp;&nbsp;&nbsp;#### $$
                  </option>
                  <option value="NC" data-humanpattern="###-###-#" data-pattern="[0-9]{3}-[0-9]{3}-[0-9]{1}" data-min="9" data-max="9">
                    Nowa Kaledonia (NC)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="NZ" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Nowa Zelandia (NZ)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="NI" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Nikaragua (NI)&nbsp;&nbsp;&nbsp;###-###-#
                  </option>
                  <option value="NE" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Niger (NE)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="NG" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Nigeria (NG)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="MP" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Mariany polnocne (MP)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="NO" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Norwegia (NO)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="OM" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Oman (OM)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="PK" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Pakistan (PK)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="PW" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Palau (PW)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="PA" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Panama (PA)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="PG" data-humanpattern="###" data-pattern="[0-9]{3}" data-min="3" data-max="3">
                    Papua Nowa Gwinea (PG)&nbsp;&nbsp;&nbsp;###
                  </option>
                  <option value="PY" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Paragwaj (PY)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="PE" data-humanpattern="!!####" data-pattern="[a-zA-Z0-9]{2}[0-9]{4}" data-min="6" data-max="6">
                    Peru (PE)&nbsp;&nbsp;&nbsp;!!####
                  </option>
                  <option value="PH" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Filipiny (PH)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="PL" data-humanpattern="##-###" data-pattern="[0-9]{2}-[0-9]{3}" data-min="6" data-max="6">
                    Polska (PL)&nbsp;&nbsp;&nbsp;##-###
                  </option>
                  <option value="PT" data-humanpattern="####-###" data-pattern="[0-9]{4}-[0-9]{3}" data-min="8" data-max="8">
                    Portugalia (PT)&nbsp;&nbsp;&nbsp;####-###
                  </option>
                  <option value="PR" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Portoryko (PR)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="QA" data-humanpattern="!!!!!" data-pattern="[A-Za-z0-9]{5}" data-min="5" data-max="5">
                    Katar (QA)&nbsp;&nbsp;&nbsp;!!!!!
                  </option>
                  <option value="RE" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Reunion (RE)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="RO" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Rumunia (RO)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="RU" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Rosja (RU)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="SM" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    San Marino (SM)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Arabia Saudyjska (SA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SN" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Senegal (SN)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="RS" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Serbia (RS)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SC" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Seszele (SC)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="SG" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Singapur (SG)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="SK" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Slowacja (SK)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SI" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Slowenia (SI)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="SO" data-humanpattern="$$ #####" data-pattern="[a-zA-Z]{2} [0-9]{5}" data-min="8" data-max="8">
                    Somalia (SO)&nbsp;&nbsp;&nbsp;$$ #####
                  </option>
                  <option value="ZA" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Rep. Poludn. Afryki (ZA)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="KR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Korea Poludniowa (KR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="ES" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Hiszpania (ES)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="LK" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Sri Lanka (LK)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="VC" data-humanpattern="$$####" data-pattern="[a-zA-Z]{2}[0-9]{4}" data-min="6" data-max="6">
                    St. Vincent (VC)&nbsp;&nbsp;&nbsp;$$####
                  </option>
                  <option value="SD" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Sudan (SD)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="SZ" data-humanpattern="$###" data-pattern="[a-zA-Z]{1}[0-9]{3}" data-min="4" data-max="4">
                    Suazi (SZ)&nbsp;&nbsp;&nbsp;$###
                  </option>
                  <option value="SE" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Szwecja (SE)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="CH" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Szwajcaria (CH)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="SY" data-humanpattern="!!!!!" data-pattern="[A-Za-z0-9]{5}" data-min="5" data-max="5">
                    Syria (SY)&nbsp;&nbsp;&nbsp;!!!!!
                  </option>
                  <option value="TW" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Tajwan (TW)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="TJ" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Tadzykistan (TJ)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="TZ" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Tanzania (TZ)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="TH" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Tajlandia (TH)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="TN" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Tunezja (TN)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="TR" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Turcja (TR)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="TM" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Turkmenistan (TM)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="TC" data-humanpattern="$$$$ #$$" data-pattern="[a-zA-Z]{4} [0-9]{1}[a-zA-Z]{2}" data-min="8" data-max="8">
                    Wyspy Turks i Caicos (TC)&nbsp;&nbsp;&nbsp;$$$$ #$$
                  </option>
                  <option value="UA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Ukraina (UA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="AE" data-humanpattern="!!!!!" data-pattern="[A-Za-z0-9]{5}" data-min="5" data-max="5">
                    Zjed.Emiraty Arabskie (AE)&nbsp;&nbsp;&nbsp;!!!!!
                  </option>
                  <option value="GB" data-humanpattern="$! !!!;$!! !!!;$!!! !!!" data-pattern="[A-Za-z]{1}[A-Za-z0-9]{1,3} [A-Za-z0-9]{3}" data-min="6" data-max="8">
                    Wielka Brytania (GB)&nbsp;&nbsp;&nbsp;$! !!!;$!! !!!;$!!! !!!
                  </option>
                  <option value="US" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    USA (US)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="UY" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Urugwaj (UY)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="UZ" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Uzbekistan (UZ)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="VA" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Watykan (VA)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="VE" data-humanpattern="####" data-pattern="[0-9]{4}" data-min="4" data-max="4">
                    Wenezuela (VE)&nbsp;&nbsp;&nbsp;####
                  </option>
                  <option value="VN" data-humanpattern="######" data-pattern="[0-9]{6}" data-min="6" data-max="6">
                    Wietnam (VN)&nbsp;&nbsp;&nbsp;######
                  </option>
                  <option value="VG" data-humanpattern="$! !!!;$!! !!!;$!!! !!!" data-pattern="[A-Za-z]{1}[A-Za-z0-9]{1,3} [A-Za-z0-9]{3}" data-min="6" data-max="8">
                    Wyspy Dziewicze (GB) (VG)&nbsp;&nbsp;&nbsp;$! !!!;$!! !!!;$!!! !!!
                  </option>
                  <option value="VI" data-humanpattern="#####-####" data-pattern="[0-9]{5}-[0-9]{4}" data-min="10" data-max="10">
                    Wyspy Dziewicze (USA) (VI)&nbsp;&nbsp;&nbsp;#####-####
                  </option>
                  <option value="WF" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Wallis &amp; Futuna (WF)&nbsp;&nbsp;&nbsp;#####
                  </option>
                  <option value="YE" data-humanpattern="!!!!!" data-pattern="[A-Za-z0-9]{5}" data-min="5" data-max="5">
                    Jemen (YE)&nbsp;&nbsp;&nbsp;!!!!!
                  </option>
                  <option value="ZM" data-humanpattern="#####" data-pattern="[0-9]{5}" data-min="5" data-max="5">
                    Zambia (ZM)&nbsp;&nbsp;&nbsp;#####
                  </option>
                </select>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-street" className="col-sm-12 col-md-2 col-form-label">
                <strong>Ulica i numer *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-street"
                  name="address1"
                  defaultValue={fullAddress.substring(0, 50)}
                  required
                  maxLength={50}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 50 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-zip" className="col-sm-12 col-md-2 col-form-label">
                <strong>Kod pocztowy *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-zip"
                  name="zip"
                  defaultValue={this.state.shippmentAddress.zip ? this.state.shippmentAddress.zip.substring(0, 16) : ""}
                  required
                  maxLength={16}
                  onChange={this.handleChange}
                />
                <small>
                  Minimalna długość: <strong id="zip-min"></strong> znaków
                </small>
                <br />
                <small>
                  Maksymalna długość: <strong id="zip-max"></strong> znaków
                </small>
                <br />
                <small>
                  Schemat: <strong id="zip-pattern"></strong>
                </small>
                <br />
                <small>
                  Podpowiedź:
                  <br />
                  <span id="zip-help">
                    # = [0..9]
                    <br />% = [A..Z]
                    <br />$ = [a..z, A..Z]
                    <br />! = [0..9, a..z, A..Z)
                  </span>
                </small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-city" className="col-sm-12 col-md-2 col-form-label">
                <strong>Miasto *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-city"
                  name="city"
                  defaultValue={this.state.shippmentAddress.city ? this.state.shippmentAddress.city.substring(0, 30) : ""}
                  required
                  maxLength={30}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 30 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-phone" className="col-sm-12 col-md-2 col-form-label">
                Telefon
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-phone"
                  name="phone1"
                  defaultValue={this.state.shippmentAddress.phone1 ? this.state.shippmentAddress.phone1.substring(0, 20) : ""}
                  maxLength={20}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 20 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-email" className="col-sm-12 col-md-2 col-form-label">
                E-mail
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-email"
                  name="email"
                  defaultValue={this.state.shippmentAddress.email ? this.state.shippmentAddress.email.substring(0, 40) : ""}
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="row mb-3">
              <label htmlFor="ship-reference" className="col-sm-12 col-md-2 col-form-label">
                Referencje
              </label>
              <div className="col-sm-12 col-md-3">
                <input type="text" className="form-control" disabled id="ship-reference" name="reference" value={this.state.order.reference.substring(0, 25)} maxLength={25} />
                <small>Maksymalna długość: 25 znaków</small>
              </div>
            </div>

            <div className="row mb-3 mt-5">
              <div className="col-12">
                <button className="btn btn-primary" type="submit">
                  Wyślij do systemu kurierskiego GLS
                </button>
              </div>
            </div>
          </form>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrderShippingPage;
