import React, { Component } from "react";
import IdleTimer from "../../utils/IdleTimer";
import utils from "../../utils/utils";

class ComponentNum extends Component {
  constructor(props) {
    utils.checkPermissions();
    super(props);

    // logout after AFK
    const timerAFK = new IdleTimer({
      timeout: 6000,
    });
  }

  checkAccessForThisPage(path) {
    let hasAccess = false;

    // if we not need check access
    if (path === undefined) {
      hasAccess = true;
    } else {
      hasAccess = utils.checkPermissionsForPath(path);
    }

    if (hasAccess) {
      return hasAccess;
    } else {
      window.location = "/";
    }
  }
}

export default ComponentNum;
