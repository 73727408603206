import React, { Component } from "react";

class LoadingPageComponent extends Component {
  constructor(props) {
    super(props);

    // this.seconds = 0;
    // this.tens = 0;
  }

  //   componentDidMount() {
  //     let Interval = null;
  //     Interval = setInterval(() => {
  //       this.tens += 10;

  //       console.log(this.tens);

  //       if (this.tens > 99) {
  //         this.seconds++;
  //         this.tens = 0;
  //       }
  //     }, 10);
  //   }

  render() {
    return (
      <>
        <div className="col-12">
          <div className="text-center mt-4">
            <div className="spinner-grow ms-auto" role="status" aria-hidden="true"></div>
            <div className="pt-3">Wczytywanie danych</div>
            {/* <p>
              <span id="seconds">{this.seconds}</span>:<span id="tens">{this.tens}</span>
            </p> */}
          </div>
        </div>
      </>
    );
  }
}

export default LoadingPageComponent;
