import React from "react";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import utils from "../utils/utils.js";
import axios from "axios";
import * as Icon from "react-feather";
import ComponentNum from "../components/override/ComponentNum";

class OrderShippingManualPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders/order/shipping/manual/");

    this.URL = "/orders/get/" + props.match.params.reference;
    this.state = {
      loading: true,
      order: null,
      shippingNumber: null,
      carrier: null,
    };
  }

  componentDidMount() {
    utils.checkPermissions();

    axios
      .get(this.URL)
      .then((res) => {
        this.setState({
          loading: false,
          order: JSON.parse(res.data.order),
        });
        document.getElementById("select-carrier-gls").click();
      })
      .catch((err) => {});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post("/shipment/send-package/manual", JSON.stringify(this.state), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res - send");
        console.log(res);

        if (res.data.status === "OK" && res.data.message === "Package added") {
          window.location = "/orders/order/" + this.state.order.reference;
        } else {
          utils.showAlert("error", res.data.message);
        }

        this.setState({
          loading: false,
        });
      });
  };

  handleShippingNumberChange = (event) => {
    this.setState({
      shippingNumber: event.target.value,
    });
  };

  handleCarrierNameChange = (event) => {
    this.setState({
      carrier: event.target.value,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienie numer: ..." />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    const breadcrumbItems = [
      { name: "Panel", path: "/" },
      { name: "Lista zamówień", path: "/orders" },
      { name: "Zamówienie numer: " + this.state.order.reference, path: "/orders/order/" + this.state.order.reference },
      { name: "Wpisz numer przesyłki", path: "" },
    ];

    return (
      <>
        <PageHeaderComponent title={"Zamówienie numer: " + this.state.order.reference} />
        <PageBodyComponent>
          <BreadcrumbComponent items={breadcrumbItems}></BreadcrumbComponent>

          <h3>Wybierz kuriera i wpisz wcześniej przygotowany numer przesyłki</h3>
          <p>Sprwadź poprawność danych odbiorcy.</p>
          <hr />

          <form className="" onSubmit={this.handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6 col-12">
                <div className="card">
                  <div className="card-header">
                    <Icon.Package className="feather" /> Adres wysyłki
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-sm-3">Firma</dt>
                      <dd className="col-sm-9">
                        {this.state.order.additionalDataNOdb.addressDelivery.copmany ? this.state.order.additionalDataNOdb.addressDelivery.copmany : "-"}
                      </dd>
                      <dt className="col-sm-3">NIP</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.nip ? this.state.order.additionalDataNOdb.addressDelivery.nip : "-"}</dd>
                      <dt className="col-sm-3">Klient</dt>
                      <dd className="col-sm-9">
                        {this.state.order.additionalDataNOdb.addressDelivery.firstname}
                        &nbsp;
                        {this.state.order.additionalDataNOdb.addressDelivery.middlename}
                        &nbsp;
                        {this.state.order.additionalDataNOdb.addressDelivery.lastname}
                      </dd>
                      <dt className="col-sm-3">Adres</dt>
                      <dd className="col-sm-9">
                        {this.state.order.additionalDataNOdb.addressDelivery.address1}
                        &nbsp;
                        {this.state.order.additionalDataNOdb.addressDelivery.address2}
                      </dd>
                      <dt className="col-sm-3">Kod</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.zip}</dd>
                      <dt className="col-sm-3">Miasto</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.city}</dd>
                      <dt className="col-sm-3">Kraj</dt>
                      <dd className="col-sm-9">
                        <img
                          style={{ margin: "0 5px", height: "14px", border: "1px solid #ddd" }}
                          src={"../../../.././images/flags/" + this.state.order.additionalDataNOdb.addressDelivery.alpha2code.toString().toLowerCase() + ".svg"}
                          alt={this.state.order.additionalDataNOdb.addressDelivery.country}
                        />
                        {this.state.order.additionalDataNOdb.addressDelivery.country} ({this.state.order.additionalDataNOdb.addressDelivery.alpha2code}) (
                        {this.state.order.additionalDataNOdb.addressDelivery.alpha3code})
                      </dd>
                      <dt className="col-sm-3">Telefon 1</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.phone1}</dd>
                      <dt className="col-sm-3">Telefon 2</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.phone2 ? this.state.order.additionalDataNOdb.addressDelivery.phone2 : "-"}</dd>
                      <dt className="col-sm-3">E-mail</dt>
                      <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.email}</dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <h4 className="border-bottom pb-3 mb-3">Wybierz kuriera</h4>
                <div className="form-check">
                  <input onChange={this.handleCarrierNameChange} value="GLS" required className="form-check-input" type="radio" name="select-carrier" id="select-carrier-gls" />
                  <label className="form-check-label" for="select-carrier-gls">
                    GLS
                  </label>
                </div>

                <div className="w-100 border-bottom pb-5 mb-5"></div>

                <h4 className="border-bottom pb-3 mb-3">Wpisz numer przesyłki</h4>
                <div className="form-check" style={{ padding: "0" }}>
                  <input
                    onChange={this.handleShippingNumberChange}
                    autoComplete="off"
                    required
                    type="text"
                    className="form-control"
                    id="shipping-number"
                    name="shipping-number"
                    style={{ maxWidth: "300px" }}
                    maxLength="100"
                  />
                </div>

                <div className="w-100 border-bottom pb-5 mb-4"></div>

                <button className="btn btn-primary" type="submit">
                  Potwierdz wysyłkę
                </button>
              </div>
            </div>
          </form>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrderShippingManualPage;
