import React from "react";
import * as Icon from "react-feather";
import { BrowserRouter, NavLink, Route, Switch } from "react-router-dom";
import NavLinkNum from "./override/NavLinkNum";

function NavComponent() {
  return (
    <>
      <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div className="position-sticky pt-3">
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLinkNum exact={true} accessPath="/" className="nav-link" activeClassName="active" ariaCurrent="page" to="/">
                <Icon.Home className="feather" />
                Pulpit
              </NavLinkNum>
            </li>
          </ul>

          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-5 mb-1 text-muted">
            <span>Główne funkcje</span>
          </h6>
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLinkNum className="nav-link" accessPath="/orders" to="/orders" activeClassName="active">
                <Icon.File className="feather" />
                Zamówienia
              </NavLinkNum>
            </li>
          </ul>

          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-5 mb-1 text-muted">
            <span>Statystyki</span>
          </h6>
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLinkNum className="nav-link" accessPath="/stats/shops" to="/stats/shops" activeClassName="active">
                <Icon.BarChart className="feather" />
                Statystyki sklepów
              </NavLinkNum>
            </li>
            <li className="nav-item">
              <NavLinkNum className="nav-link" accessPath="/stats/allegro" to="/stats/allegro" activeClassName="--active">
                <Icon.BarChart2 className="feather" />
                Statystyki Allegro
              </NavLinkNum>
            </li>
          </ul>

          {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Saved reports</span>
            <a
              className="link-secondary"
              href="#"
              aria-label="Add a new report"
            >
              <span data-feather="plus-circle"></span>
            </a>
          </h6>
          <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <a className="nav-link" href="#">
                <Icon.File className="feather" />
                Current month
              </a>
            </li>
          </ul> */}
        </div>
      </nav>
    </>
  );
}

export default NavComponent;
