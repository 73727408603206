class IdleTimer {
  constructor({ timeout }) {
    this.timeout = timeout;

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    if (!localStorage.getItem("_expiredTime")) {
      this.updateExpiredTime();
    }

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
      if (expiredTime < Date.now()) {
        this.logout();
      }
    }, 1000);
  }

  updateExpiredTime() {
    localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
  }

  tracker() {
    // window.addEventListener("mousemove", this.eventHandler);
  }

  logout() {
    localStorage.removeItem("jwt-token");
    window.location = "/";
  }
}

export default IdleTimer;
