import axios from "axios";
import React from "react";
import LoadingPageComponent from "../components/LoadingPageComponent";
import ComponentNum from "../components/override/ComponentNum";
import PageBodyComponent from "../components/PageBodyComponent";
import PageHeaderComponent from "../components/PageHeaderComponent";
import utils from "../utils/utils";
import NavLinkNum from "../components/override/NavLinkNum";
import * as Icon from "react-feather";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

class StatsShopsPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/stats/shops");

    this.URL = "/stats/get-shops-sources";
    this.URL2 = "/stats/get-stats/lit_pl,lit_fr,lit_de,lit_en,lit_it,lit_pt,lit_es/13";
    this.state = {
      loading: true,
      shopsSources: null,
      statsData: null,
      allOrdersCount: null,
      key: Math.random(),
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Value",
              },
              suggestedMin: -10,
              suggestedMax: 200,
            },
          },
        },
      },
      chartData: {
        labels: ["t"],
        datasets: [
          {
            label: "Dataset 1",
            data: [0],
            borderColor: "rgb(255, 99, 132)",
            fill: true,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
          {
            label: "Dataset 2",
            data: [0],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
    };
  }

  componentDidMount() {
    utils.checkPermissions();

    axios
      .get(this.URL)
      .then((res) => {
        this.setState({
          shopsSources: JSON.parse(res.data.shops),
        });

        axios
          .get(this.URL2)
          .then((res) => {
            this.setState({
              loading: false,
              statsData: JSON.parse(JSON.stringify(res.data)),
              chartData: this.setChartData(res.data),
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  setChartData = (data) => {
    let statsData = JSON.parse(JSON.stringify(data));
    let chartData = {
      labels: statsData.months_range,
      datasets: [],
    };

    let shopsColors = {
      DK: "#a1f6d9",
      LIT_DE: "#31a769",
      LIT_EN: "#565d8f",
      LIT_ES: "#4bd1f1",
      LIT_FR: "#f16cb1",
      LIT_IT: "#5a6952",
      LIT_PL: "#c7fa43",
      LIT_PT: "#da7014",
      PK: "#c051c8",
      UK: "#e57b8b",
    };

    for (const [key, value] of Object.entries(statsData.stats)) {
      let data = [];
      for (const [mKey, mValue] of Object.entries(value.months)) {
        data.push(mValue.value_of_orders);
      }
      chartData.datasets.push({
        label: key,
        data: data,
        fill: true,
        borderColor: shopsColors[key],
        backgroundColor: shopsColors[key],
      });
    }

    return chartData;
  };

  handleGetStatsClick = () => {
    let shopSource = document.getElementById("shop-source-select").value;
    let monthsRange = document.getElementById("month-range-select").value;

    this.setState({
      loading: true,
    });
    axios
      .get("/stats/get-stats/" + shopSource + "/" + monthsRange)
      .then((res) => {
        this.setState({
          loading: false,
          statsData: JSON.parse(JSON.stringify(res.data)),
          chartData: this.setChartData(res.data),
        });
      })
      .catch((err) => {});
    return false;
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Statystyki sklepów" />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    return (
      <>
        <PageHeaderComponent title="Statystyki sklepów" />
        <PageBodyComponent>
          <div className="col-md-4 col-12">
            <h5 className="pb-2 mb-3 pb-2 border-bottom">Wybierz sklep</h5>
            <select defaultValue={"lit_pl,lit_fr,lit_de,lit_en,lit_it,lit_pt,lit_es"} className="form-select" id="shop-source-select">
              <option value="all">Wszystkie</option>
              <option disabled>──────────</option>
              <option value="pk,uk,dk">Wszystkie komunijne</option>
              <option value="lit_pl,lit_fr,lit_de,lit_en,lit_it,lit_pt,lit_es">Wszystkie liturgiczne</option>
              <option disabled>──────────</option>
              {this.state.shopsSources.map((shopSource, index) => {
                return (
                  <option key={index} value={shopSource}>
                    {shopSource}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-md-4 col-12">
            <h5 className="pb-2 mb-3 pb-2 border-bottom">Wybierz okres statystyk</h5>
            <select defaultValue={"13"} className="form-select" id="month-range-select">
              <option value="3">3 miesiące</option>
              <option value="6">6 miesięcy</option>
              <option value="12">12 miesięcy</option>
              <option value="13">13 miesięcy</option>
              <option value="24">24 miesiące</option>
              <option value="25">25 miesiące</option>
            </select>
          </div>

          <div className="col-md-4 col-12 pt-4 d-flex align-items-center justify-content-center">
            <button type="button" className="btn btn-primary btn-lg" onClick={this.handleGetStatsClick}>
              Pokaż statystyki
            </button>
          </div>

          <div className="col-12 border-bottom mb-4 pb-4"></div>

          <div className="col-lg-9 col-md-12">
            <div className="card">
              <h5 className="card-header">Wykres</h5>

              <div className="card-body">
                <Line options={this.state.chartOptions} data={this.state.chartData} />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="card">
              <h5 className="card-header">Podsumowanie</h5>
              <div className="card-body">
                <strong>Statystyki dotyczą zamówień opłaconych i wysłanych</strong>
                <hr />
                <dl className="row">
                  <dt className="col-sm-12">Zakres dat:</dt>
                  <dd className="col-sm-12">
                    {this.state.statsData.months_range[0]} - {this.state.statsData.months_range[this.state.statsData.months_range.length - 1]}
                    {/* <ul>
                      {this.state.statsData.months_range.map((month, index) => {
                        return (
                          <>
                            <li>{month}</li>{" "}
                          </>
                        );
                      })}
                    </ul> */}
                  </dd>
                  <dt className="col-sm-12">Wybrane sklepy:</dt>
                  <dd className="col-sm-12">
                    {this.state.statsData.shops.map((shop, index) => {
                      return (
                        <>
                          <span key={index}>
                            {shop}
                            {", "}
                          </span>
                        </>
                      );
                    })}
                  </dd>
                  <dt className="col-sm-12">Liczba zamówień w tym okresie:</dt>
                  <dd className="col-sm-12">{this.state.statsData.stats_sum.all_orders_count}</dd>
                  <dt className="col-sm-12">Wartość zamówień w tym okresie:</dt>
                  <dd className="col-sm-12">
                    {new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(this.state.statsData.stats_sum.all_value_of_orders)}
                    <br />
                    <small style={{ fontSize: "12px" }}>wartość w PLN brutto</small>
                  </dd>
                  <dt className="col-sm-12">Średnia wartość jednego zamówienia:</dt>
                  <dd className="col-sm-12">
                    {new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(
                      this.state.statsData.stats_sum.all_value_of_orders / this.state.statsData.stats_sum.all_orders_count
                    )}
                    <br />
                    <small style={{ fontSize: "12px" }}>wartość w PLN brutto</small>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card mt-3">
              <h5 className="card-header">Tabela</h5>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover table-sm align-middle num-stats-table-font-small">
                    <thead>
                      <tr className="table-primary align-middle">
                        <th className="wp-10"> </th>

                        {this.state.statsData.shops.map((shop, index) => {
                          return (
                            <>
                              <th key={index} className="col text-center">
                                {shop}
                              </th>
                            </>
                          );
                        })}

                        <th className="wp-10 text-center">
                          Suma
                          <br />
                          miesiąc
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.statsData.months_range.map((month, index) => {
                        let value_of_orders_for_month = 0;
                        let count_of_orders_for_month = 0;
                        return (
                          <>
                            <tr key={index}>
                              <th className="text-center">{month}</th>
                              {Object.entries(this.state.statsData.stats).map((shop, index_shop) => {
                                value_of_orders_for_month += shop[1]["months"][month].value_of_orders;
                                count_of_orders_for_month += shop[1]["months"][month].orders_count;
                                return (
                                  <>
                                    {
                                      <td key={index_shop} className="text-center">
                                        <strong>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(shop[1]["months"][month].value_of_orders)}</strong>
                                        <br />
                                        <small>{shop[1]["months"][month].orders_count} zamówień</small>
                                      </td>
                                    }
                                  </>
                                );
                              })}
                              <td className="text-center table-active">
                                <strong>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(value_of_orders_for_month)}</strong>
                                <br />
                                <small>{count_of_orders_for_month} zamówień</small>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr className="table-success pt-5 pb-5">
                        <td className="text-center">
                          <strong>W wybranym okresie</strong>
                        </td>
                        {Object.entries(this.state.statsData.stats).map((shop, index) => {
                          return (
                            <>
                              <td key={index} className="text-center">
                                <strong>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(shop[1].value_of_orders)}</strong>
                                <br />
                                <small>{shop[1].orders_count} zamówień</small>
                              </td>
                            </>
                          );
                        })}
                        <td className="text-center table-active">
                          <strong>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(this.state.statsData.stats_sum.all_value_of_orders)}</strong>
                          <br />
                          <small>{this.state.statsData.stats_sum.all_orders_count} zamówień</small>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </PageBodyComponent>
      </>
    );
  }
}

export default StatsShopsPage;
