import React, { Component } from "react";
import axios from "axios";

class AfterLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    axios
      .get("security/get-user-role")
      .then((res) => {
        console.log(res);
        // localStorage.setItem("user-allowed-paths", JSON.stringify(res.data));
        localStorage.setItem("user-role", res.data);
        window.location = "/";
      })
      .catch((err) => {
        // console.log("err");
        // console.log(err);
      });
  }

  render() {
    return <></>;
  }
}

export default AfterLoginPage;
