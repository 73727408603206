import React from "react";
import { Link, NavLink } from "react-router-dom";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import axios from "axios";
import utils from "../utils/utils.js";
import * as Icon from "react-feather";
// import * as flagsIcons from "../images/flags";
import Flags from "../components/CountriesFlagsComponent";
import ComponentNum from "../components/override/ComponentNum";
import NavLinkNum from "../components/override/NavLinkNum";

class OrdersPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders");

    this.shop = "all";
    if (props.match.params.shop) {
      this.shop = props.match.params.shop;
    }

    this.URL = "/orders/get-orders/";
    this.state = {
      loading: true,
      orders: null,
      key: Math.random(),
      //   searchString: "",
    };
  }
  componentDidMount() {
    utils.checkPermissions();

    // this.setState({
    //   searchString: "",
    // });

    axios
      .get(this.URL + this.shop)
      .then((res) => {
        console.log("res");
        console.log(res);
        this.setState({
          loading: false,
          orders: JSON.parse(res.data.orders),
        });
        document.querySelectorAll(".shop-filter-selector.active").forEach((i) => {
          i.classList.remove("active");
        });
        if (document.querySelector("#shop-btn-" + this.shop)) {
          document.querySelector("#shop-btn-" + this.shop).classList.add("active");
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    console.log(this.props);
    this.setState({
      loading: true,
    });

    axios
      .get(this.URL + this.props.match.params.shop)
      .then((res) => {
        console.log("res");
        console.log(res);
        this.setState({
          loading: false,
          orders: JSON.parse(res.data.orders),
        });
        document.querySelectorAll(".shop-filter-selector.active").forEach((i) => {
          i.classList.remove("active");
        });
        document.querySelector("#shop-btn-" + this.props.match.params.shop).classList.add("active");
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  handleRefreshClick = () => {
    window.location.reload(false);
    // sometimes works wrong, if you've chosen some shop and after that you've chosen "all shop" and hit refresh,
    // then loads orders from shop not from all shops
    // this.setState({
    //   loading: true,
    // });
    // this.componentDidMount();
  };

  handleSearchClick = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .get(this.URL + "all/" + document.getElementById("search-string").value)
      .then((res) => {
        this.setState({
          loading: false,
          orders: JSON.parse(res.data.orders),
          //searchString: document.getElementById("search-string").value,
        });
      })
      .catch((err) => {
        console.log("err search");
        console.log(err);
      });
    return false;
  };

  hendleEnterPressSearch = (e) => {
    var keyPressed = e.keyCode || e.which;
    if (keyPressed === 13) {
      this.handleSearchClick(e);
    }
  };

  //   filterOrders(shopName) {
  //     this.setState({
  //       loading: true,
  //     });
  //     // document.querySelector(".shop-filter-selector.active").classList.remove("active");
  //     // document.querySelector("#shop-btn-" + shopName).classList.add("active");
  //     axios
  //       .get(this.URL + shopName)
  //       .then((res) => {
  //         console.log("res");
  //         console.log(res);
  //         this.setState({
  //           loading: false,
  //           orders: JSON.parse(res.data.orders),
  //         });
  //       })
  //       .catch((err) => {
  //         console.log("err");
  //         console.log(err);
  //       });
  //   }

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienia" />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    return (
      <>
        <PageHeaderComponent title="Zamówienia" />
        <PageBodyComponent>
          <div className="col-md-7 col-12">
            <h5 className="pb-2 mb-3 pb-2 border-bottom">Wybierz sklep</h5>
            {/* <button className="btn btn-outline-info btn-sm me-1 shop-filter-selector" id="shop-btn-all" onClick={this.filterOrders.bind(this, "all")}>
              Wszystkie
            </button>
            <button className="btn btn-outline-info btn-sm ms-1 me-1 shop-filter-selector" id="shop-btn-PK" onClick={this.filterOrders.bind(this, "PK")}>
              PK
            </button> */}

            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-all"
              to={"/orders/shop/all"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              Wszystkie
            </NavLinkNum>
            <br />
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-pk"
              to={"/orders/shop/pk"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              PK
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-uk"
              to={"/orders/shop/uk"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              UK
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-uk"
              to={"/orders/shop/uk_de"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              UK DE
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-uk"
              to={"/orders/shop/uk_en"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              UK EN
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-uk"
              to={"/orders/shop/uk_fr"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              UK FR
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-dk"
              to={"/orders/shop/dk"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              DK
            </NavLinkNum>
            <br />
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_pl"
              to={"/orders/shop/lit_pl"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT PL
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_fr"
              to={"/orders/shop/lit_fr"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT FR
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_de"
              to={"/orders/shop/lit_de"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT DE
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_en"
              to={"/orders/shop/lit_en"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT EN
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_it"
              to={"/orders/shop/lit_it"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT IT
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_es"
              to={"/orders/shop/lit_es"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT ES
            </NavLinkNum>
            <NavLinkNum
              className="btn btn-outline-primary btn-sm ms-1 me-1 shop-filter-selector"
              id="shop-btn-lit_pt"
              to={"/orders/shop/lit_pt"}
              accessPath="/orders/shop/"
              activeClassName="active"
            >
              LIT PT
            </NavLinkNum>
          </div>

          <div className="col-md-3 col-12">
            <h5 className="pb-2 mb-3 pb-2 border-bottom">Wyszukaj zamówienie</h5>
            <div className="row">
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="search-string"
                  //   placeholder={this.state.searchString !== "" ? this.state.searchString : "Wpisz numer zamówienia"}
                  placeholder="Wpisz numer zamówienia"
                  onKeyPress={this.hendleEnterPressSearch}
                />
                <div className="text-right">
                  <NavLinkNum className="form-text" to={"/orders/shop/all"} accessPath="/orders/shop/">
                    <Icon.X size={14} className="feather" />
                    Wyczyść wyszukiwanie
                  </NavLinkNum>
                </div>
              </div>
              <div className="col-4">
                <button style={{ width: "100%" }} onClick={this.handleSearchClick} className="btn btn-primary">
                  <Icon.Search size={14} className="feather" /> Szukaj
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-12 d-flex align-items-center justify-content-end">
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.handleRefreshClick}>
              <Icon.RefreshCw size={14} className="feather" />
              &nbsp;&nbsp; Odśwież
            </button>
          </div>
          <div className="col-12 border-bottom mb-4 pb-4"></div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-sm align-middle">
              <thead>
                <tr>
                  <th className="wp-5">Sklep</th>
                  <th className="wp-5">Numer</th>
                  <th className="wp-17">Klient</th>
                  <th className="wp-7">Kraj</th>
                  <th className="wp-8">Razem</th>
                  <th className="wp-9">Płatność</th>
                  <th className="wp-10">Status</th>
                  <th className="wp-7">Data</th>
                  <th className="wp-10">Faktura</th>
                  <th className="wp-8">Dokumenty</th>
                  {/* <th className="wp-7">Akcje</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.orders.map((order, index) => {
                  // console.log(order.additionalDataNOdb);
                  return (
                    <tr key={index}>
                      <td className={"text-center td-shop td-shop-" + order.shopSource.toString().toLowerCase()}>{order.shopSource}</td>
                      <td className="text-center">
                        <NavLinkNum
                          className={"btn btn-outline-info btn-shop btn-shop-" + order.shopSource.toString().toLowerCase()}
                          accessPath="/orders/order/"
                          to={"/orders/order/" + order.reference}
                          activeClassName="active"
                        >
                          <strong>{order.reference}</strong>
                        </NavLinkNum>
                      </td>
                      <td>
                        {order.additionalDataNOdb.addressDelivery.firstname} {order.additionalDataNOdb.addressDelivery.middlename}{" "}
                        {order.additionalDataNOdb.addressDelivery.lastname}
                      </td>
                      <td className="text-center">
                        <Flags
                          style={{ height: "14px", border: "1px solid #ddd" }}
                          countryCode={order.additionalDataNOdb.addressDelivery.alpha2code.toString().toLowerCase()}
                          alt={order.additionalDataNOdb.addressDelivery.country}
                        ></Flags>
                        <br />
                        {order.additionalDataNOdb.addressDelivery.country}
                      </td>
                      <td className="text-right">
                        {parseFloat(order.totalPaid).toFixed(2)} {order.currencyISO}
                      </td>
                      <td className="text-center">
                        <small>{order.paymentType}</small>
                      </td>
                      <td className="text-center">
                        <small>
                          <span className={order.additionalDataNOdb.status.cssClass}>{order.additionalDataNOdb.status.name}</span>
                        </small>
                      </td>
                      <td className="text-center">
                        {/* {order.dateAdd.toDateString()} */}

                        {new Intl.DateTimeFormat("pl-PL", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: false,
                          timeZone: "Europe/Warsaw",
                        }).format(new Date(order.dateAdd))}
                      </td>
                      <td className="text-center">
                        <strong>{order.invoiceNumber}</strong>
                      </td>
                      <td className="text-center">
                        {/* <a href="#" className="btn btn-success btn-sm">
                          FV
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" className="btn btn-success btn-sm">
                          Zam
                        </a> */}
                      </td>
                      {/* <td className="text-center">
                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <div className="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" className="btn btn-default border dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              MENU
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <li>
                                <NavLink className="dropdown-item" to={"/orders/order/" + order.reference} activeClassName="active">
                                  Pokaż
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrdersPage;
