import { NavLink } from "react-router-dom";
import utils from "../../utils/utils";

// USE it for render Button with checking permissions

function ButtonNum(params) {
  let hasAccess = false;

  // if we not need check access
  if (params.accessPath === undefined) {
    hasAccess = true;
  } else {
    hasAccess = utils.checkPermissionsForPath(params.accessPath);
  }

  return (
    <>
      {hasAccess ? (
        <button className={params.className} onClick={params.onClick}>
          {params.children}
        </button>
      ) : (
        <span className={params.className + " disabled"}>{params.children}</span>
      )}
    </>
  );
}

export default ButtonNum;
