import axios from "axios";
import React, { Component } from "react";
import "../styles/Login.scss";
import utils from "../utils/utils";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    localStorage.removeItem("_expiredTime");
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      username: this.username,
      password: this.password,
      //   withCredentials: true,
    };

    axios
      .post("login_check", data)
      .then((res) => {
        console.log("res");
        console.log(res);
        localStorage.setItem("jwt-token", res.data.token);
        utils.login();

        // window.location.reload();
        window.location = "/after-login-page";
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  };

  render() {
    return (
      <section id="login-form-wrapper">
        <main className="form-signin">
          <form onSubmit={this.handleSubmit}>
            <h1 className="h3 mb-3 fw-normal">Logowanie</h1>

            <div className="form-floating">
              <input type="text" className="form-control" id="username" placeholder="Login" onChange={(e) => (this.username = e.target.value)} />
              <label htmlFor="username">Nazwa użytkownika</label>
            </div>
            <div className="form-floating">
              <input type="password" className="form-control" id="password" placeholder="Hasło" onChange={(e) => (this.password = e.target.value)} />
              <label htmlFor="password">Hasło</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit">
              Zaloguj się
            </button>
            <p className="mt-5 mb-3 text-muted">&copy; "Marka" System 2002 - {new Date().getFullYear()}</p>
          </form>
        </main>
      </section>
    );
  }
}

export default LoginPage;
