import React from "react";

function PageBodyComponent(props) {
  return (
    <>
      <div className="row pt-3 pb-2 mb-3 ">{props.children}</div>
    </>
  );
}

export default PageBodyComponent;
