import { NavLink } from "react-router-dom";
import utils from "../../utils/utils";

// USE it for render NavLink with checking permissions

function NavLinkNum(params) {
  let hasAccess = false;

  // if we not need check access
  if (params.accessPath === undefined) {
    hasAccess = true;
  } else {
    hasAccess = utils.checkPermissionsForPath(params.accessPath);
  }

  return (
    <>
      {hasAccess ? (
        <NavLink exact={params.exact} className={params.className} activeClassName={params.activeClassName} aria-current={params.ariaCurrent} to={params.to}>
          {params.children}
        </NavLink>
      ) : (
        <span className={params.className + " disabled"}>{params.children}</span>
      )}
    </>
  );
}

export default NavLinkNum;
