/**
 * ALL PATH
 * 
 * 
    "/",
    "/after-login-page",
    "/orders",
    "/orders/shop/",
    "/orders/search",
    "/orders/order/",
    "/orders/order/shipping/gls/",
    "/orders/order/shipping/manual/",
    "/orders/order/shipping/label/gls/",
    "/order/order-status/change-status/paid/",
    "/order/order-status/change-status/cancel/",
 * 
 * 
 */

class hasAccess {
  checkPath(path) {
    let pathACL = [];
    pathACL["ROLE_ADMIN"] = [];
    pathACL["ROLE_WORKER"] = [
      "/",
      "/after-login-page",
      "/orders",
      "/orders/shop/",
      "/orders/search",
      "/orders/order/",
      "/orders/order/shipping/gls/",
      "/orders/order/shipping/dpd/",
      "/orders/order/shipping/paczkomatinpost/",
      "/orders/order/shipping/courierinpost/",
      "/orders/order/shipping/manual/",
      "/orders/order/shipping/label/gls/",
      "/orders/order/shipping/label/dpd/",
      "/orders/order/shipping/label/paczkomatinpost/",
      "/orders/order/shipping/label/courierinpost/",
      //   "/stats/shops",
      //   "/order/order-status/change-status/paid/", // deny
      //   "/order/order-status/change-status/cancel/", // deny
    ];

    const userRole = localStorage.getItem("user-role");
    if (userRole === "ROLE_ADMIN") {
      return true;
    }

    if (pathACL[userRole]) {
      let hasAccessToPath = pathACL[userRole].find((str) => str === path);
      if (hasAccessToPath !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export default new hasAccess();
