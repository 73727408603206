import React from "react";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import utils from "../utils/utils.js";
import axios from "axios";
import { fireEvent } from "@testing-library/dom";
import ComponentNum from "../components/override/ComponentNum";
import carrierLogo from "../images/carriers/inpost_logo.png";

class OrderShippingPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders/order/shipping/paczkomatinpost/");

    this.URL = "/orders/get/" + props.match.params.reference;

    this.state = {
      loading: true,
      order: null,
      shippmentAddress: null,
    };
  }

  componentDidMount() {
    utils.checkPermissions();

    axios
      .get(this.URL)
      .then((res) => {
        let tempShippmentAddress = JSON.parse(res.data.order).additionalDataNOdb.addressDelivery;
        tempShippmentAddress["reference"] = res.data.order.reference;

        this.fullName = tempShippmentAddress.firstname + (tempShippmentAddress.middlename ? " " + tempShippmentAddress.middlename : "") + " " + tempShippmentAddress.lastname;
        tempShippmentAddress["fullname"] = this.fullName.substring(0, 40);

        this.targetPoint = (tempShippmentAddress.address1 ? tempShippmentAddress.address1 : "").replace('PACZKOMAT: ', '').trim();
        tempShippmentAddress["targetPoint"] = this.targetPoint;

        this.pointAddress = (tempShippmentAddress.address2 ? " " + tempShippmentAddress.address2 : "").replace(',', '').trim();
        tempShippmentAddress["pointAddress"] = this.pointAddress;

        if (tempShippmentAddress["phone1"]) {
          tempShippmentAddress["phone1"] = tempShippmentAddress.phone1.trim().replaceAll("-", "").replaceAll("+48", "").replaceAll("+", "").replaceAll(" ", "").substring(0, 9);
        } else {
          tempShippmentAddress["phone1"] = "";
        }


        this.setState({
          loading: false,
          order: JSON.parse(res.data.order),
          shippmentAddress: tempShippmentAddress,
        });

        fireEvent.change(document.getElementById("ship-country"), { target: { value: this.state.shippmentAddress.alpha2code } });

        //this.setPatternToZipField();
      })
      .catch((err) => { });
  }

  handleChange = (event) => {
    let tempShippmentAddress = this.state.shippmentAddress;
    tempShippmentAddress[event.target.name] = event.target.value;
    this.setState({
      shippmentAddress: tempShippmentAddress,
    });
  };

  handleChangeCountry = (event) => {
    this.handleChange(event);
    this.setPatternToZipField();
  };

  setPatternToZipField = () => {
    let countrySelect = document.getElementById("ship-country");
    let selectedCountry = countrySelect.options[countrySelect.selectedIndex];
    selectedCountry.click();
    let zipField = document.getElementById("ship-zip");
    zipField.setAttribute("pattern", selectedCountry.dataset.pattern);
    document.getElementById("zip-min").innerText = selectedCountry.dataset.min;
    document.getElementById("zip-max").innerText = selectedCountry.dataset.max;
    document.getElementById("zip-pattern").innerText = selectedCountry.dataset.humanpattern;
    // [0-9]{2}-[0-9]{3}
  };

  handleFilterCountries = (event) => {
    let keyword = event.target.value;
    let countrySelect = document.getElementById("ship-country");

    for (var i = 0; i < countrySelect.length; i++) {
      var txt = countrySelect.options[i].text;
      if (txt.substring(0, keyword.length).toLowerCase() !== keyword.toLowerCase() && keyword.trim() !== "") {
        countrySelect.options[i].style.display = "none";
      } else {
        countrySelect.options[i].style.display = "block";
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    // this.state.shippmentAddress["fullname"] = "test";
    axios
      .post("/shipment/send-package/paczkomaty-inpost", JSON.stringify(this.state.shippmentAddress), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res");
        console.log(res);

        if (res.data.status === "OK" && res.data.message === "Package added") {
          // window.location = "/orders/order/" + this.state.order.reference;
          window.location = "/orders/order/shipping/label/paczkomatinpost/" + this.state.order.reference + "/" + res.data.packageId;
        } else {
          utils.showAlert(res.data.status, res.data.message);
        }

        this.setState({
          loading: false,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienie numer: ..." />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    const breadcrumbItems = [
      { name: "Panel", path: "/" },
      { name: "Lista zamówień", path: "/orders" },
      { name: "Zamówienie numer: " + this.state.order.reference, path: "/orders/order/" + this.state.order.reference },
      { name: "Wysyłka (Paczkomat InPost)", path: "" },
    ];

    return (
      <>
        <PageHeaderComponent title={"Zamówienie numer: " + this.state.order.reference} />
        <PageBodyComponent>
          <BreadcrumbComponent items={breadcrumbItems}></BreadcrumbComponent>
          <div className="row border-bottom mb-4 pb-4 pt-2">
            <div className="col-md-2 col-sm-12">
              <img style={{ height: "auto", width: "auto", maxWidth: "100%" }} src={carrierLogo} alt="logo" />
            </div>
            <div className="col-md-10 col-sm-12">
              <h3>Formularz tylko dla wysyłek Paczkomat InPost</h3>
              <p>Sprwadź poprawność danych.</p>
            </div>
          </div>

          <form className="" onSubmit={this.handleSubmit}>
            <div className="row mb-3">
              <label htmlFor="ship-fullname" className="col-sm-12 col-md-2 col-form-label">
                <strong>Imię i nazwisko *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-fullname"
                  name="fullname"
                  disabled
                  defaultValue={this.fullName.substring(0, 40)}
                  required
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-targetPoint" className="col-sm-12 col-md-2 col-form-label">
                <strong>Paczkomat *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-targetPoint"
                  name="targetPoint"
                  defaultValue={this.targetPoint.substring(0, 65)}
                  required
                  maxLength={65}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 65 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-fullAddress" className="col-sm-12 col-md-2 col-form-label">
                <strong>Ulica i numer *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-fullAddress"
                  name="fullAddress"
                  disabled
                  defaultValue={this.pointAddress.substring(0, 65)}
                  required
                  maxLength={65}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 65 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-zip" className="col-sm-12 col-md-2 col-form-label">
                <strong>Kod pocztowy *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-zip"
                  name="zip"
                  disabled
                  defaultValue={this.state.shippmentAddress.zip ? this.state.shippmentAddress.zip.substring(0, 16) : ""}
                  required
                  maxLength={16}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-city" className="col-sm-12 col-md-2 col-form-label">
                <strong>Miasto *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-city"
                  name="city"
                  disabled
                  defaultValue={this.state.shippmentAddress.city ? this.state.shippmentAddress.city.substring(0, 30) : ""}
                  required
                  maxLength={30}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 30 znaków</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-phone" className="col-sm-12 col-md-2 col-form-label">
                <strong>Telefon *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-phone"
                  name="phone1"
                  defaultValue={
                    this.state.shippmentAddress.phone1 ?
                      this.state.shippmentAddress.phone1 :
                      ""
                  }
                  pattern="[0-9]{9}"
                  required
                  maxLength={20}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 9 znaków</small><br />
                <small>Bez znaków specjalnych (+, -), bez kodu kraju, bez spacji.</small>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="row mb-3">
              <label htmlFor="ship-email" className="col-sm-12 col-md-2 col-form-label">
                <strong>E-mail *</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="ship-email"
                  name="email"
                  defaultValue={this.state.shippmentAddress.email ? this.state.shippmentAddress.email.substring(0, 40) : ""}
                  required
                  maxLength={40}
                  onChange={this.handleChange}
                />
                <small>Maksymalna długość: 40 znaków</small>
              </div>
            </div>

            <div className="row mb-3">
              <label htmlFor="ship-reference" className="col-sm-12 col-md-2 col-form-label">
                <strong>Referencje</strong>
              </label>
              <div className="col-sm-12 col-md-3">
                <input type="text" className="form-control" disabled id="ship-reference" name="reference" value={this.state.order.reference.substring(0, 25)} maxLength={25} />
                <small>Maksymalna długość: 25 znaków</small>
              </div>
            </div>



            <div className="row mb-3 mt-5">
              <div className="col-12">
                <button className="btn btn-primary" type="submit">
                  Wyślij do systemu kurierskiego Paczkomaty InPost
                </button>
              </div>
            </div>
          </form>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrderShippingPage;
