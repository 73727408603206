import React from "react";
import ComponentNum from "../components/override/ComponentNum";
import PageHeaderComponent from "../components/PageHeaderComponent";
import hasAccess from "../utils/hasAccess";

class DesktopPage extends ComponentNum {
  constructor(props) {
    super(props);
    this.state = {};

    this.checkAccessForThisPage("/");
  }
  render() {
    return (
      <>
        <PageHeaderComponent title="Pulpit" />
      </>
    );
  }
}

export default DesktopPage;
