import React from "react";
import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import NavLinkNum from "./override/NavLinkNum";

function BreadcrumbComponent(params) {
  return (
    <>
      <div className="col-12">
        <nav aria-label="breadcrumb" className="border-bottom pb-3 mb-3">
          <ol className="breadcrumb">
            {params.items.map((item) => {
              return (
                <>
                  {item.path ? (
                    <li className="breadcrumb-item">
                      <NavLinkNum to={item.path} accessPath={item.path}>
                        {item.name}
                      </NavLinkNum>
                    </li>
                  ) : (
                    <li className="breadcrumb-item active" aria-current="page">
                      {item.name}
                    </li>
                  )}
                </>
              );
            })}
          </ol>
        </nav>
      </div>
    </>
  );
}

export default BreadcrumbComponent;
