import hasAccess from "./hasAccess";

class utils {
  isLogged() {
    if (localStorage.getItem("jwt-token") === null) {
      return false;
    }
    return true;
  }

  hasAccess() {
    let hasAccess = false;
    hasAccess = this.isLogged();
    return hasAccess;
  }

  checkPermissions() {
    let hasPermissions = this.hasAccess();
    if (!hasPermissions) {
      window.location = "/";
    }
    return hasPermissions;
  }

  checkPermissionsForPath(path) {
    let hasPermissions = this.isLogged();

    if (path) {
      hasPermissions = hasAccess.checkPath(path);
    }

    // let userAllowedPaths = JSON.parse(localStorage.getItem("user-allowed-paths"));

    // let hasAccessToPath = userAllowedPaths.find((str) => str === path);
    // if (hasAccessToPath !== undefined) {
    //   hasPermissions = true;
    // } else {
    //   hasPermissions = false;
    // }

    return hasPermissions;
  }

  showAlert(type, message) {
    let alertBox = document.getElementById("alert-box");

    let alertCssClass = "";
    let alertTitle = "";
    switch (type.toLowerCase()) {
      case "error":
        alertCssClass = "alert-danger";
        alertTitle = "Błąd";
        break;
      case "ok":
        alertCssClass = "alert-success";
        alertTitle = "Wykonano poprawnie";
        break;
    }

    alertBox.querySelector("h5").innerText = alertTitle;
    alertBox.querySelector("p").innerHTML = message;
    alertBox.classList.add(alertCssClass);
    alertBox.classList.add("show");
    alertBox.style.display = "block";

    setTimeout(() => {
      alertBox.classList.remove("show");
      alertBox.style.display = "none";
    }, 5000);
  }

  login() {
    localStorage.setItem("user-role", JSON.stringify([]));
    // localStorage.setItem("user-allowed-paths", JSON.stringify([]));
  }

  logout() {
    localStorage.clear();
    window.location = "/";
  }

  carrierMap(orderCarrier) {
    let carriers = {
      PaczkomatInPost: [
        "Paczkomat InPost",
        "InPost Paczkomaty"
      ],
      KurierInPost: [
        // lit pl
        "Kurier (PL)",
        "Darmowa dostawa",
        "Kurier Standard (S1)",
        // "Kurier Standard (S2)",
        // pk, dk
        "Kurier",
      ],
      GLS: [
        // // lit pl
        // "Kurier (PL)",
        // "Darmowa dostawa",
        // "Kurier Standard (S1)",
        // // "Kurier Standard (S2)",
        // // pk, dk
        // "Kurier",
        // // lit en
        // "Courier Standard (PL)",
        // "Free Delivery",
        // "Courier Standard (S1)",
        // // "Courier Standard (S2)",
        // // lit it
        // "Corriere (PL)",
        // "Consegna gratuita",
        // "Corriere standard (S1)",
        // // "Corriere standard (S2)",
        // // lit de
        // "Standard Kurier (PL)",
        // "Kostenlose Lieferung",
        // "Standard Kurier (S1)",
        // // "Standard Kurier (S2)",
        // // lit es
        // "Mensajero (PL)",
        // "Entrega gratis",
        // "Mensajería estándar (S1)",
        // // "Mensajería estándar (S2)",
        // // lit fr
        // "Courrier Normal (PL)",
        // "Livraison gratuite",
        // "Courrier Normal (S1)",
        // // "Courrier Normal (S2)",
        // // lit pt
        // "Correio (PL)",
        // "Entrega grátis",
        // "Correio normal (S1)",
        // // "Correio normal (S2)",
      ],
      DPD: [],
    };
    let carrierToUse = [];
    Object.keys(carriers).forEach((c) => {
      if (carriers[c].includes(orderCarrier)) {
        carrierToUse.push(c);
      }
    });
    // console.log("carrier to use" + carrierToUse);
    // console.log("carrierMap: " + carrierToUse);
    return carrierToUse;
  }

  checkIfCanUseThisCarrier(carriersArray, carrierName) {
    return carriersArray.some((item) => item === carrierName);
  }
}

export default new utils();
