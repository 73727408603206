import React from "react";
import { NavLink } from "react-router-dom";
import PageHeaderComponent from "../components/PageHeaderComponent";
import PageBodyComponent from "../components/PageBodyComponent";
import LoadingPageComponent from "../components/LoadingPageComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import axios from "axios";
import * as Icon from "react-feather";
import Flags from "../components/CountriesFlagsComponent";
import ComponentNum from "../components/override/ComponentNum";
import NavLinkNum from "../components/override/NavLinkNum";
import ButtonNum from "../components/override/ButtonNum";
import utils from "../utils/utils.js";

class OrderPage extends ComponentNum {
  constructor(props) {
    super(props);

    this.checkAccessForThisPage("/orders/order/");

    this.URL = "/orders/get/" + props.match.params.reference;
    // console.log(props);
    this.state = {
      loading: true,
      orders: null,
    };
  }
  componentDidMount() {
    // console.log(this.state);

    axios
      .get(this.URL)
      .then((res) => {
        // console.log("res");
        // console.log(JSON.parse(res.data.order));
        this.setState({
          loading: false,
          order: JSON.parse(res.data.order),
        });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  handleChangeStatusClick(newStatus) {
    this.setState({
      loading: true,
    });

    axios
      .get("/order-status/change-status/" + this.state.order.shopSource + "/" + this.state.order.idOrderInShop + "/" + newStatus)
      .then((res) => {
        // console.log(res);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  carrierTrackingUrl = {
    gls: "https://gls-group.eu/PL/pl/sledzenie-paczek?match=%TRACKING_ID%",
    dpd: "https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=%TRACKING_ID%",
  };

  getTrackingUrl(carrier, trackingId) {
    //if (carrier === "Kurier") {
    //  return "";
    //}

    if (this.carrierTrackingUrl[carrier.toLowerCase()]) {
      const rawUrl = this.carrierTrackingUrl[carrier.toLowerCase()];
      const url = rawUrl.replace("%TRACKING_ID%", trackingId);
      return (
        <>
          :<span>&nbsp;&nbsp;</span>
          <a href={url} target="_blank" rel="noreferrer">
            {trackingId}
          </a>
        </>
      );
    } else {
      return "";
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageHeaderComponent title="Zamówienie numer: ..." />
          <PageBodyComponent>
            <LoadingPageComponent />
          </PageBodyComponent>
        </>
      );
    }

    const breadcrumbItems = [
      { name: "Panel", path: "/" },
      { name: "Lista zamówień", path: "/orders" },
      { name: "Zamówienie numer: " + this.state.order.reference, path: "" },
    ];

    const carrierToUse = utils.carrierMap(this.state.order.carrier);

    return (
      <>
        <PageHeaderComponent title={"Zamówienie numer: " + this.state.order.reference} />
        <PageBodyComponent>
          <BreadcrumbComponent items={breadcrumbItems}></BreadcrumbComponent>
          <div className="col-md-4">
            <div className="row">
              <div className="col-12 mb-4">
                <div className="card">
                  <div className="card-header">
                    <Icon.Info className="feather" /> Informacje podstawowe
                  </div>

                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-sm-3">Numer</dt>
                      <dd className="col-sm-9">{this.state.order.reference}</dd>
                      <dt className="col-sm-3">Data</dt>
                      <dd className="col-sm-9">
                        {new Intl.DateTimeFormat("pl-PL", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: false,
                          timeZone: "Europe/Warsaw",
                        }).format(new Date(this.state.order.dateAdd))}
                      </dd>
                      <dt className="col-sm-3">Status</dt>
                      <dd className="col-sm-9">
                        <span className={this.state.order.additionalDataNOdb.status.cssClass}>{this.state.order.additionalDataNOdb.status.name}</span>
                      </dd>
                      <dt className="col-sm-3">Sklep</dt>
                      <dd className="col-sm-9">{this.state.order.shopSource}</dd>
                      <dt className="col-sm-3">Wysyłka</dt>
                      <dd className="col-sm-9">
                        {this.state.order.carrier}

                        {this.state.order.carrier !== "Kurier" ? this.getTrackingUrl(this.state.order.carrier, this.state.order.shippingNumber) : ""}
                        { }
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <Icon.DollarSign className="feather" /> Informacje finansowe
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-sm-3">Płatność</dt>
                      <dd className="col-sm-9">{this.state.order.paymentType}</dd>
                      <dt className="col-sm-3">Przelicznik</dt>
                      <dd className="col-sm-9">{this.state.order.conversionRate}</dd>
                      <dt className="col-sm-3">Waluta</dt>
                      <dd className="col-sm-9">{this.state.order.currencyISO}</dd>
                      <dt className="col-sm-3">Produkty</dt>
                      <dd className="col-sm-9">{parseFloat(this.state.order.totalProductsWt).toFixed(2)}</dd>
                      <dt className="col-sm-3">Wysyłka</dt>
                      <dd className="col-sm-9">{parseFloat(this.state.order.totalShipping).toFixed(2)}</dd>
                      <dt className="col-sm-3">Rabat</dt>
                      <dd className="col-sm-9">{parseFloat(this.state.order.totalDiscounts).toFixed(2)}</dd>
                      <dt className="col-sm-3">Suma</dt>
                      <dd className="col-sm-9">{parseFloat(this.state.order.totalPaid).toFixed(2)}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="card">
                      <div className="card-header">
                        <Icon.Settings className="feather" /> Akcje
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-12 text-center">
                            {/* <div className="btn-group" role="group" aria-label="">
                              <a href="#" className="btn btn-success border">
                                Pobierz fakturę
                              </a>
                              <a href="#" className="btn btn-success border">
                                Pobierz zamówienie
                              </a> 
                            </div> */}
                          </div>
                          <div className="col-md-12 col-sm-12 text-center">
                            <div className="btn-group" role="group" aria-label="">

                              {
                                this.state.order.additionalDataNOdb.status.globalStatus == "PAYMENT_SUCCESS" &&
                                  // this.state.order.additionalDataNOdb.status.globalStatus == "CANCELED" && 
                                  !this.state.order.shippingNumber ? (
                                  <>
                                    {utils.checkIfCanUseThisCarrier(carrierToUse, "GLS") ? (
                                      <>
                                        <NavLinkNum
                                          className="btn btn-success border"
                                          to={"/orders/order/shipping/gls/" + this.state.order.reference}
                                          accessPath="/orders/order/shipping/gls/"
                                        >
                                          Wyślij GLS
                                        </NavLinkNum>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {utils.checkIfCanUseThisCarrier(carrierToUse, "DPD") ? (
                                      <>
                                        <NavLinkNum
                                          className="btn btn-success border"
                                          to={"/orders/order/shipping/dpd/" + this.state.order.reference}
                                          accessPath="/orders/order/shipping/dpd/"
                                        >
                                          Wyślij DPD
                                        </NavLinkNum>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {utils.checkIfCanUseThisCarrier(carrierToUse, "KurierInPost") ? (
                                      <>
                                        <NavLinkNum
                                          className="btn btn-success border"
                                          to={"/orders/order/shipping/courierinpost/" + this.state.order.reference}
                                          accessPath="/orders/order/shipping/courierinpost/"
                                        >
                                          Wyślij InPost Kurier
                                        </NavLinkNum>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {utils.checkIfCanUseThisCarrier(carrierToUse, "PaczkomatInPost") ? (
                                      <>
                                        <NavLinkNum
                                          className="btn btn-success border"
                                          to={"/orders/order/shipping/paczkomatinpost/" + this.state.order.reference}
                                          accessPath="/orders/order/shipping/paczkomatinpost/"
                                        >
                                          Wyślij Inpost Paczkomat
                                        </NavLinkNum>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {utils.checkIfCanUseThisCarrier(carrierToUse, "MANUAL") ? (
                                      <>
                                        <NavLinkNum
                                          className="btn btn-success border"
                                          to={"/orders/order/shipping/manual/" + this.state.order.reference}
                                          accessPath="/orders/order/shipping/manual/"
                                        >
                                          Wpisz numer przesyłki
                                        </NavLinkNum>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                              {this.state.order.packageId && this.state.order.carrier === "GLS" ? (
                                <NavLinkNum
                                  className="btn btn-success border"
                                  to={"/orders/order/shipping/label/gls/" + this.state.order.reference + "/" + this.state.order.packageId}
                                  accessPath="/orders/order/shipping/label/gls/"
                                >
                                  Pobierz etykietę
                                </NavLinkNum>
                              ) : (
                                ""
                              )}
                              {this.state.order.packageId && this.state.order.carrier === "DPD" ? (
                                <NavLinkNum
                                  className="btn btn-success border"
                                  to={"/orders/order/shipping/label/dpd/" + this.state.order.reference + "/" + this.state.order.packageId}
                                  accessPath="/orders/order/shipping/label/dpd/"
                                >
                                  Pobierz etykietę
                                </NavLinkNum>
                              ) : (
                                ""
                              )}
                              {this.state.order.packageId && this.state.order.carrier === "PaczkomatInPost" ? (
                                <NavLinkNum
                                  className="btn btn-success border"
                                  to={"/orders/order/shipping/label/paczkomatinpost/" + this.state.order.reference + "/" + this.state.order.packageId}
                                  accessPath="/orders/order/shipping/label/paczkomatinpost/"
                                >
                                  Pobierz etykietę
                                </NavLinkNum>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {this.state.order.packageId && this.state.order.carrier === "KurierInPost" ? (
                                <NavLinkNum
                                  className="btn btn-success border"
                                  to={"/orders/order/shipping/label/courier-inpost/" + this.state.order.reference + "/" + this.state.order.packageId}
                                  accessPath="/orders/order/shipping/label/courier-inpost/"
                                >
                                  Pobierz etykietę
                                </NavLinkNum>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {this.state.order.additionalDataNOdb.status.globalStatus == "WAITING_FOR_PAYMENT" ? (
                                <ButtonNum
                                  className="btn btn-success border"
                                  onClick={this.handleChangeStatusClick.bind(this, "paid")}
                                  accessPath="/order/order-status/change-status/paid/"
                                >
                                  Oznacz jako opłacone
                                </ButtonNum>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {this.state.order.additionalDataNOdb.status.globalStatus == "WAITING_FOR_PAYMENT" ? (
                                // <NavLink className="btn btn-danger border" to={"/orders/order/" + this.state.order.reference}>
                                //   Anuluj
                                // </NavLink>
                                <ButtonNum
                                  className="btn btn-danger border"
                                  onClick={this.handleChangeStatusClick.bind(this, "cancel")}
                                  accessPath="/order/order-status/change-status/cancel/"
                                >
                                  Anuluj
                                </ButtonNum>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="card">
                      <div className="card-header">
                        <Icon.File className="feather" /> Adres faktury
                      </div>
                      <div className="card-body">
                        <dl className="row">
                          <dt className="col-sm-3">Firma</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressInvoice.copmany ? this.state.order.additionalDataNOdb.addressInvoice.copmany : "-"}
                          </dd>
                          <dt className="col-sm-3">NIP</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressInvoice.nip ? this.state.order.additionalDataNOdb.addressInvoice.nip : "-"}</dd>
                          <dt className="col-sm-3">Klient</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressInvoice.firstname}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressInvoice.middlename}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressInvoice.lastname}
                          </dd>
                          <dt className="col-sm-3">Adres</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressInvoice.address1}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressInvoice.address2}
                          </dd>
                          <dt className="col-sm-3">Kod</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressInvoice.zip}</dd>
                          <dt className="col-sm-3">Miasto</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressInvoice.city}</dd>
                          <dt className="col-sm-3">Kraj</dt>
                          <dd className="col-sm-9">
                            <Flags
                              style={{ margin: "0 5px", height: "14px", border: "1px solid #ddd" }}
                              countryCode={this.state.order.additionalDataNOdb.addressInvoice.alpha2code.toString().toLowerCase()}
                              alt={this.state.order.additionalDataNOdb.addressInvoice.country}
                            ></Flags>
                            {this.state.order.additionalDataNOdb.addressInvoice.country} ({this.state.order.additionalDataNOdb.addressInvoice.alpha2code}) (
                            {this.state.order.additionalDataNOdb.addressInvoice.alpha3code})
                          </dd>
                          <dt className="col-sm-3">Telefon 1</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressInvoice.phone1}</dd>
                          <dt className="col-sm-3">Telefon 2</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressInvoice.phone2 ? this.state.order.additionalDataNOdb.addressInvoice.phone2 : "-"}
                          </dd>
                          <dt className="col-sm-3">E-mail</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressInvoice.email}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="card">
                      <div className="card-header">
                        <Icon.Package className="feather" /> Adres wysyłki
                      </div>
                      <div className="card-body">
                        <dl className="row">
                          <dt className="col-sm-3">Firma</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressDelivery.copmany ? this.state.order.additionalDataNOdb.addressDelivery.copmany : "-"}
                          </dd>
                          <dt className="col-sm-3">NIP</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.nip ? this.state.order.additionalDataNOdb.addressDelivery.nip : "-"}</dd>
                          <dt className="col-sm-3">Klient</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressDelivery.firstname}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressDelivery.middlename}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressDelivery.lastname}
                          </dd>
                          <dt className="col-sm-3">Adres</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressDelivery.address1}
                            &nbsp;
                            {this.state.order.additionalDataNOdb.addressDelivery.address2}
                          </dd>
                          <dt className="col-sm-3">Kod</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.zip}</dd>
                          <dt className="col-sm-3">Miasto</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.city}</dd>
                          <dt className="col-sm-3">Kraj</dt>
                          <dd className="col-sm-9">
                            <Flags
                              style={{ margin: "0 5px", height: "14px", border: "1px solid #ddd" }}
                              countryCode={this.state.order.additionalDataNOdb.addressDelivery.alpha2code.toString().toLowerCase()}
                              alt={this.state.order.additionalDataNOdb.addressDelivery.country}
                            ></Flags>
                            {this.state.order.additionalDataNOdb.addressDelivery.country} ({this.state.order.additionalDataNOdb.addressDelivery.alpha2code}) (
                            {this.state.order.additionalDataNOdb.addressDelivery.alpha3code})
                          </dd>
                          <dt className="col-sm-3">Telefon 1</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.phone1}</dd>
                          <dt className="col-sm-3">Telefon 2</dt>
                          <dd className="col-sm-9">
                            {this.state.order.additionalDataNOdb.addressDelivery.phone2 ? this.state.order.additionalDataNOdb.addressDelivery.phone2 : "-"}
                          </dd>
                          <dt className="col-sm-3">E-mail</dt>
                          <dd className="col-sm-9">{this.state.order.additionalDataNOdb.addressDelivery.email}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-header">
                    <Icon.ShoppingCart className="feather" /> Produkty
                  </div>

                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="wp-5">Lp.</th>
                          <th className="wp-58">Nazwa</th>
                          <th className="wp-15 text-right">Cena prod.</th>
                          <th className="wp-7 text-center">Ilość</th>
                          <th className="wp-7 text-right">Rabat</th>
                          <th className="wp-8 text-right">SUMA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.order.additionalDataNOdb.products.map((product, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <strong dangerouslySetInnerHTML={{ __html: product.name }}></strong>
                                <br></br>
                                <br></br>

                                {Array.isArray(product.model)
                                  ? product.model.map((model) => {
                                    return (
                                      <>
                                        <small dangerouslySetInnerHTML={{ __html: model }}></small>
                                        {/* {model} */}
                                        <br />
                                      </>
                                    );
                                  })
                                  : ""}

                                {Array.isArray(product.customs)
                                  ? product.customs.map((customs) => {
                                    return (
                                      <>
                                        <small dangerouslySetInnerHTML={{ __html: customs }}></small>
                                        {/* {model} */}
                                        <br />
                                        {/* {customs} <br /> */}
                                      </>
                                    );
                                  })
                                  : ""}

                                {/* {Array.isArray(product.customfields)
                                  ? product.customfields.map((customfield) => {
                                      if (customfield.customfield_value === "textinput") {
                                        let product_attribute = JSON.parse(product.product_attribute);
                                        let product_attribute_value = product_attribute[customfield.virtuemart_custom_id];
                                        return (
                                          <>
                                            {customfield.custom_title}: {product_attribute_value[customfield.virtuemart_customfield_id]["comment"]} &nbsp; | &nbsp;
                                          </>
                                        );
                                      } else if (customfield.is_list == "1" && customfield.is_input == "1") {
                                        let product_attribute = JSON.parse(product.product_attribute);
                                        let product_attribute_value = product_attribute[customfield.virtuemart_custom_id];
                                        return (
                                          <>
                                            {customfield.custom_title}: {product_attribute_value[customfield.virtuemart_customfield_id]} &nbsp; | &nbsp;
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {customfield.custom_title}: {customfield.customfield_value} &nbsp; | &nbsp;
                                          </>
                                        );
                                      }
                                    })
                                  : ""} */}
                              </td>
                              <td className="text-right">
                                {parseFloat(product.productSubtotalWithTax).toFixed(2) !== parseFloat(product.productSubtotalDiscount).toFixed(2) &&
                                  parseFloat(product.productSubtotalDiscount).toFixed(2) > 0.0 ? (
                                  <>
                                    <s>{parseFloat(product.productPriceBase).toFixed(2)}</s>
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                <b>{parseFloat(product.productPriceFinal).toFixed(2)}</b>
                              </td>
                              <td className="text-center">{product.quantity}</td>
                              <td className="text-right">{parseFloat(product.productSubtotalDiscount).toFixed(2)}</td>
                              <td className="text-right">
                                {parseFloat(product.productSubtotalWithTax).toFixed(2) !== parseFloat(product.productSubtotalDiscount).toFixed(2) &&
                                  parseFloat(product.productSubtotalDiscount).toFixed(2) > 0.0 ? (
                                  <>
                                    <s>{parseFloat(product.quantity * product.productPriceBase).toFixed(2)}</s>
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                <b>{parseFloat(product.productSubtotalWithTax).toFixed(2)}</b>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-header">
                    <Icon.List className="feather" /> Historia statusów zamówienia
                  </div>

                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="wp-20">Data</th>
                          <th className="wp-30 text-left">Nazwa</th>
                          <th className="wp-50 text-left">Komentarz</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.order.additionalDataNOdb.statusHistory)
                          ? this.state.order.additionalDataNOdb.statusHistory.map((status) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {new Intl.DateTimeFormat("pl-PL", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      second: "numeric",
                                      hour12: false,
                                      timeZone: "Europe/Warsaw",
                                    }).format(new Date(status.dateAdd))}
                                  </td>
                                  <td>{status.statusName}</td>
                                  <td>
                                    <div dangerouslySetInnerHTML={{ __html: status.statusComment }} />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageBodyComponent>
      </>
    );
  }
}

export default OrderPage;
